import React from 'react'
import { Image, Flex, Container, Button, VStack, Heading, Text } from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import Card from '../../atoms/Card'

const ArchivedRestoring = () => {
	return (
		<>
			<Container maxW="container.xl" py="1rem">
				<Card p="1rem" alignItems="center">
					<VStack spacing="1rem">
						<Image src="/assets/images/graphics/new_vidday.svg" alt="VidDay Archived" />
						<Heading as="h3" variant="hero">
							Your VidDay is waking up
						</Heading>
						<Text>
							<strong>
								Your event page was laid to rest because it&apos;s been a while since we&apos;ve seen
								you.
							</strong>
						</Text>
						<Text>
							<strong>We have begun to wake it up from it&apos;s sleepy slumber.</strong>
						</Text>
						<Text>Your media will take approximately 12 hours to wake up.</Text>
						<br />
						<Flex justify="center">
							<Button 
							// as={Link} 
							as="a" href="/dashboard">
								Back to Dashboard
							</Button>
						</Flex>
						<br />
					</VStack>
				</Card>
			</Container>
		</>
	)
}

export default ArchivedRestoring
